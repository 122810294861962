// import React from "react";
import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/common/layout";
import Seo from "../components/common/seo";
import { fullDate } from "../utils/date_format";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from 'moment';
import 'moment/locale/fr';  

const formatDateInFrench = (date) => {
  let formattedDate = moment(date).locale('fr').format('MMM DD YYYY');
  formattedDate = formattedDate.replace(/\./g, '');
  return formattedDate.toUpperCase(); 
};

const fullDateInEnglish = (date) => {
  const currentLocale = moment.locale(); 
  moment.locale('en');
  const formattedDate = fullDate(date); 
  moment.locale(currentLocale); 
  return formattedDate;
};

const NewsTemplate = (props) => {
  const { allContentfulNews } = props.data;
  const { numPages, currentPage } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/news" : `/news/${currentPage - 1}`;
  const nextPage = `/news/${currentPage + 1}`;

  const deduplicateNews = (newsItems) => {
    const uniqueNews = new Map();
    newsItems.forEach((item) => {
      const uniqueKey = `${item.title}-${item.publishedAt}`;
      if (!uniqueNews.has(uniqueKey)) {
        uniqueNews.set(uniqueKey, item);
      }
    });
    return Array.from(uniqueNews.values()); 
  };

  const allNews = deduplicateNews(allContentfulNews.nodes);
  const englishNews = allNews.filter((news) => news.frenchText !== "French");
  const frenchNews = allNews.filter((news) => news.frenchText === "French");
  const firstFrenchNews = allNews.find(
    (news) => news.frenchText && Array.isArray(news.frenchText) && news.frenchText.includes("French") && news.featured === true
  );
  
const featuredEnglishNews = allNews.find(
  (news) => (!news.frenchText || (Array.isArray(news.frenchText) && !news.frenchText.includes("French"))) && news.featured === true
);
    // Log the fetched data
    useEffect(() => {
      console.log("Fetched News Data:", allContentfulNews);
  }, [allContentfulNews]);
  
  useEffect(() => {
    console.log("Filtered French News Article:", firstFrenchNews);
  }, [firstFrenchNews]);
  

  return (
    <div className="newsPage">
      <Layout>
        <Seo title="News" />
        {/* <p>Number of items being displayed: {frenchTextCount} - {sliceEnd}</p> */}
        <div className="main_content_wrapper insight_page">
          <div className="spotlight_article_section">
            <div className="container">
              {allNews.length && allNews[0] && (
                <div className="article_block">
                <div className="article_image_block">
                  {featuredEnglishNews && (
                    featuredEnglishNews.externalLink?.startsWith("http") ? (
                      featuredEnglishNews.image && (
                        <a
                          href={featuredEnglishNews.externalLink}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          <GatsbyImage
                            image={getImage(featuredEnglishNews.image)}
                            alt="Featured article"
                          />
                        </a>
                      )
                    ) : (
                      featuredEnglishNews.slug && featuredEnglishNews.image && (
                        <Link to={`/news/${featuredEnglishNews.slug}`}>
                          <GatsbyImage
                            image={getImage(featuredEnglishNews.image)}
                            alt="Featured article"
                          />
                        </Link>
                      )
                    )
                  )}
                </div>
                <div className="article_info_block">
                  <div className="spot_light_red">
                    <p>Spotlight Article</p>
                  </div>
                  <div className="article_inner">
                    <p className="rn_date_label">
                    {featuredEnglishNews?.publishedAt && fullDateInEnglish(featuredEnglishNews.publishedAt)}

                      </p>
                    <div className="common_inner_title">
                    {featuredEnglishNews?.externalLink?.startsWith("http") ? (
                      <a
                        href={featuredEnglishNews.externalLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <h3>{featuredEnglishNews.title}</h3>
                      </a>
                    ) : featuredEnglishNews?.slug ? (
                      <Link to={`/news/${featuredEnglishNews.slug}`}>
                        <h3>{featuredEnglishNews.title}</h3>
                      </Link>
                    ) : null}       
                      <p className="info">{featuredEnglishNews?.excerpt?.excerpt}</p>
                      <div className="common_section_btn common_btn">
                        {featuredEnglishNews?.externalLink?.startsWith("http") ? (
                          <a
                            href={featuredEnglishNews.externalLink}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <span className="btn_text">Learn More</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="38.938" height="8.071" viewBox="0 0 38.938 8.071">
                              <g id="right-arrow" transform="translate(-1192.844 -692.433)">
                                <path id="Icon_open-arrow-left" data-name="Icon open-arrow-left" d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116" />
                                <path id="Icon_open-arrow-left_-_Outline" data-name="Icon open-arrow-left - Outline" d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116" />
                              </g>
                            </svg>
                          </a>
                            ) : featuredEnglishNews?.slug && featuredEnglishNews.image ? (

                          <Link to={`/news/${featuredEnglishNews.slug}`}>
                            <span className="hover_off btn_text">Read More</span>
                            <span className="hover_on btn_text">Read More</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="38.938" height="8.071" viewBox="0 0 38.938 8.071">
                              <g id="right-arrow" transform="translate(-1192.844 -692.433)">
                                <path id="Icon_open-arrow-left" data-name="Icon open-arrow-left" d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116" />
                                <path id="Icon_open-arrow-left_-_Outline" data-name="Icon open-arrow-left - Outline" d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z" transform="translate(1231 699.427) rotate(180)" fill="#bf1116" />
                              </g>
                            </svg>
                          </Link>
                       ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
{firstFrenchNews && (
  <div className="article_block frenchArticleBlock">
    <div className="article_image_block">
      {firstFrenchNews.externalLink?.startsWith("http") ? (
        <a
          href={firstFrenchNews.externalLink}
          rel="noreferrer noopener"
          target="_blank"
        >
          <GatsbyImage
            image={getImage(firstFrenchNews.image)}
            alt={firstFrenchNews.title}
          />
        </a>
      ) : (
        <Link to={`/news/${firstFrenchNews.slug}`}>
          <GatsbyImage
            image={getImage(firstFrenchNews.image)}
            alt={firstFrenchNews.title}
          />
        </Link>
      )}
    </div>
    <div className="article_info_block">
      <div className="spot_light_red">
        <p>Article en Vedette</p> {/* French Article Indicator */}
      </div>
      <div className="article_inner">
        <div className="common_inner_title">
          <p className="rn_date_label">
            {formatDateInFrench(firstFrenchNews.publishedAt)}
          </p>
          {firstFrenchNews.externalLink?.startsWith("http") ? (
            <a
              href={firstFrenchNews.externalLink}
              rel="noreferrer"
              target="_blank"
            >
              <h3>{firstFrenchNews.title}</h3>
            </a>
          ) : (
            <Link to={`/news/${firstFrenchNews.slug}`}>
              <h3>{firstFrenchNews.title}</h3>
            </Link>
          )}
          <p className="info">{firstFrenchNews.excerpt.excerpt}</p>
          <div className="common_section_btn common_btn">
            {firstFrenchNews.externalLink?.startsWith("http") ? (
              <a
                href={firstFrenchNews.externalLink}
                rel="noreferrer"
                target="_blank"
              >
                <span className="hover_off btn_text">Lire la Suite</span>
                <span className="hover_on btn_text">Lire la Suite</span>
              </a>
            ) : (
              <Link to={`/news/${firstFrenchNews.slug}`}>
                <span className="hover_off btn_text">Lire la Suite</span>
                <span className="hover_on btn_text">Lire la Suite</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

              <div className="insight_card_block">
                <div className="all_insight_cards">                 
                {allNews.length &&
  englishNews
    .slice(0, 52)
    .map(news => {
      const isFeaturedEnglishNews = news.title === featuredEnglishNews?.title; // Check if the article is the featured English news
      const isFirstFrenchNews = news.title === firstFrenchNews?.title; // Check if the article is the featured French news

      return (
        <div className={`insight_card ${isFeaturedEnglishNews || isFirstFrenchNews ? 'hideThis' : ''} ${news.frenchText ? news.frenchText : ''}`} key={news.id}>
          <div className="recent_news_box">
            <div className="recent_news_img">
              {news.externalLink.startsWith("http") ? (
                <a
                  href={news.externalLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  <GatsbyImage
                    image={getImage(news.image)}
                    alt="blog"
                  />
                </a>
              ) : (
                <Link to={`/news/${news.slug}`} tabindex="0">
                  <GatsbyImage
                    image={getImage(news.image)}
                    alt="blog"
                  />
                </Link>
              )}
            </div>
            <div className="recent_news_content">
              <p className="rn_date_label en-only">
                {fullDateInEnglish(news.publishedAt)}
              </p>
              <p className="rn_date_label fr-only">
                {formatDateInFrench(news.publishedAt)}
              </p>
              {news.externalLink.startsWith("http") ? (
                <div>
                  <a href={news.externalLink} rel="noreferrer" target="_blank">
                    <h3 className="rn_title_text">{news.title}</h3>
                  </a>
                  <p className="cinfo"> {news.excerpt.excerpt} </p>
                </div>
              ) : (
                <Link to={`/news/${news.slug}`} tabindex="0">
                  <h3 className="rn_title_text">{news.title}</h3>
                </Link>
              )}
              <div className="educion_learn_more_btn">
                {news.externalLink.startsWith("http") ? (
                  <a
                    href={news.externalLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <span className="en-only">Read More</span>
                    <span className="fr-only">Lire la Suite</span>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </a>
                ) : (
                  <Link to={`/news/${news.slug}`} tabindex="0">
                    <span className="en-only">Read More</span>
                    <span className="fr-only">Lire la Suite</span>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    })
}


{allNews.length && frenchNews
  .map(news => {
    const isFeaturedArticle = news.title === firstFrenchNews?.title;

    return (
      <div className={`test insight_card ${isFeaturedArticle ? 'hideThis' : ''} ${news.frenchText ? news.frenchText : ''}`} key={news.id}>
        <div className="recent_news_box ">
          <div className="recent_news_img">
            {news.externalLink.startsWith("http") ? (
              <a
                href={news.externalLink}
                rel="noreferrer"
                target="_blank"
              >
                <GatsbyImage
                  image={getImage(news.image)}
                  alt="blog"
                />
              </a>
            ) : (
              <Link to={`/news/${news.slug}`} tabindex="0">
                <GatsbyImage
                  image={getImage(news.image)}
                  alt="blog"
                />
              </Link>
            )}
          </div>
          <div className="recent_news_content">
            <p className="rn_date_label">
              {news.externalLink.startsWith("#") && fullDate(news.publishedAt)}
            </p>
            {news.externalLink.startsWith("http") ? (
              <a href={news.externalLink} rel="noreferrer" target="_blank">
                <h3 className="rn_title_text">{news.title}</h3>
              </a>
            ) : (
              <Link to={`/news/${news.slug}`} tabindex="0">
                <h3 className="rn_title_text">{news.title}</h3>
              </Link>
            )}
            <p className="cinfo">{news.excerpt.excerpt}</p>
            <div className="educion_learn_more_btn">
              {news.externalLink.startsWith("http") ? (
                <a href={news.externalLink} rel="noreferrer" target="_blank">
                  Read More
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
              ) : (
                <Link to={`/news/${news.slug}`} tabindex="0">
                  Read More
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })}



                </div>
              </div>

              {allNews.length >= 7 && allNews[7] && (
                <div className="article_block v2">
                  <div className="article_info_block">
                    <div className="spot_light_red">
                      <p>spotlight article</p>
                    </div>
                    <div className="article_inner">
                      <p className="rn_date_label">
                        {allNews[7].externalLink.startsWith("#") &&
                          fullDate(allNews[7].publishedAt)}
                      </p>
                      <div className="common_inner_title">
                        {allNews[7].externalLink.startsWith("http") ? (
                          <a
                            href={allNews[7].externalLink}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <h3>{allNews[7].title}</h3>
                          </a>
                        ) : (
                          <Link to={allNews[7].slug}>
                            <h3>{allNews[7].title}</h3>
                          </Link>
                        )}
                        <p className="info">{allNews[7].excerpt.excerpt}</p>
                        <div className="common_section_btn">
                          {allNews[7].externalLink.startsWith("http") ? (
                            <a
                              href={allNews[7].externalLink}
                              rel="noreferrer"
                              target="_blank"
                              className="common_btn"
                            >
                              <span className="hover_off btn_text">
                                {" "}
                                Learn More{" "}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38.938"
                                height="8.071"
                                viewBox="0 0 38.938 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1192.844 -692.433)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#bf1116"
                                  />
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#bf1116"
                                  />
                                </g>
                              </svg>
                            </a>
                          ) : (
                            <Link to={allNews[7].slug} className="common_btn">
                              <span className="">
                                {" "}
                                Learn More{" "}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38.938"
                                height="8.071"
                                viewBox="0 0 38.938 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1192.844 -692.433)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#bf1116"
                                  />
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#bf1116"
                                  />
                                </g>
                              </svg>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="article_image_block">
                    {allNews[7].externalLink.startsWith("http") ? (
                      <a
                        href={allNews[7].externalLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <GatsbyImage
                          image={getImage(allNews[7].image)}
                          alt="artical"
                        />
                      </a>
                    ) : (
                      <Link to={allNews[7].slug}>
                        {/* <img src={allNews[7].image.file.url} alt="artical" /> */}
                        <GatsbyImage
                          image={getImage(allNews[7].image)}
                          alt="artical"
                        />
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="obie_awards_pagination_wrap">
          <div className="common_pagination_wrap">
            {!isFirst && (
              <Link to={prevPage} rel="prev" className="pagination_next">
                <i className="fa fa-caret-left" aria-hidden="true"></i>
              </Link>
            )}
            <ul className="common_pagination">
              {Array.from({ length: numPages }, (_, i) => (
                <li key={`pagination-number${i + 1}`}>
                  <Link to={`${i === 0 ? "/news/" : "/news/" + (i + 1)}`}>
                    <button
                      type="button"
                      className={i + 1 === currentPage && "active"}
                    >
                      {i + 1}
                    </button>
                  </Link>
                </li>
              ))}
            </ul>
            {!isLast && (
              <Link to={nextPage} rel="next" className="pagination_next">
                <i className="fa fa-caret-right" aria-hidden="true"></i>
              </Link>
            )}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default NewsTemplate

export const news = graphql`
query newsTemp($skip: Int!, $limit: Int = 50) {
  allContentfulNews(
    limit: $limit
    skip: $skip
    sort: { fields: publishedAt, order: DESC }
  ) {
    nodes {
      id
      title
      frenchText
      featured
      slug
      externalLink
      publishedAt
      image {
        gatsbyImageData
      }
      excerpt {
        excerpt
      }
    }
  }
}
`
